import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, meta, title }) {
	const { site } = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					title
					description
					author
				}
			}
		}
	`);

	const metaDescription = description || site.siteMetadata.description;

	return (
		<Helmet
			htmlAttributes={{
				lang: 'en-US'
			}}
			title={title}
			titleTemplate={`%s | ${site.siteMetadata.title}`}
			meta={[
				// Security/privacy-related.
				{
					name: 'referrer',
					contnet: 'strict-origin-when-cross-origin'
				},

				// SEO-related.
				{
					name: 'description',
					content: metaDescription
				},
				{
					property: 'og:title',
					content: title
				},
				{
					property: 'og:description',
					content: metaDescription
				},
				{
					property: 'og:type',
					content: 'website'
				},
				{
					name: 'twitter:card',
					content: 'summary'
				},
				{
					name: 'twitter:creator',
					content: site.siteMetadata.author
				},
				{
					name: 'twitter:title',
					content: title
				},
				{
					name: 'twitter:description',
					content: metaDescription
				},
				...meta
			]}
		/>
	);
}

SEO.defaultProps = {
	meta: [],
	description: ''
};

SEO.propTypes = {
	description: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired
};

export default SEO;
